import React, { useContext, useEffect, useState } from "react"
import Page from "../components/page"
import { LangContext } from "../hooks/lang.js"
const Contact = () => {
  
  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null
  let [dynamicData, setDynamicData] = useState(null)

  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "pageContact", locale: lang })
      .then(p => {
        let dd = p.items[0].fields

        dd.liensSociaux = dd.liensSociaux.map(ls => ls.fields)
        dd.contacts = dd.contacts.map(c => c.fields)
        dd.carte = dd.carte.fields.file.url

        setDynamicData(dd)
      })
  }, [lang])

  if (dynamicData) {
    return (
      <div className="contact">
        <section className="section1 contact__section1">
          <h1>Contact</h1>
        </section>

        <section className="contact__section2">
          <div className="contact__social-links">
            {dynamicData.liensSociaux.map(ls => (
              <a
                key={ls.nom}
                className="collaborateur__social-link"
                href={ls.lien}
              >
                {ls.nom}
              </a>
            ))}
          </div>

          <div className="contact__columns">
            {dynamicData.contacts.map(c => (
              <div key={c.nom} className="contact__column">
                <h2>{c.nom}</h2>
                <div>{c.email}</div>
                <div>{c.Telephone}</div>
              </div>
            ))}
          </div>
        </section>
        <img
          className="map"
          src={dynamicData.carte}
          alt="Map to find JDF, it's in little italy"
        />
      </div>
    )
  } else {
    return <div>Chargement</div>
  }
}

const ContactWithPage = props => (
  <Page>
    <Contact {...props} />
  </Page>
)
export default ContactWithPage
